/**
 * Klaviyo Back in Stock Web Component
 * Handles both displaying the popup and sending BIS requests to Klaviyo
 */
class BISForm extends HTMLElement {
  constructor() {
    super();

    // Set up form submission handler
    this.form = this.querySelector('form');
    this.form.addEventListener('submit', this.onSubmitHandler.bind(this));

    // Set up close buttons
    document.querySelectorAll('.klaviyo-bis-close').forEach((elem) => {
      elem.addEventListener('click', this.onClickHandler.bind(this));
    });

    // Set up the global event listener for BIS triggers
    this.setupBisTriggers();

    console.log('Klaviyo BIS component initialized');
  }

  /**
   * Sets up event delegation for BIS trigger buttons
   */
  setupBisTriggers() {
    // Use event delegation at document level to catch all trigger clicks
    document.addEventListener(
      'click',
      (event) => {
        const trigger = event.target.closest('.klaviyo-bis-trigger');
        if (trigger) {
          event.preventDefault();
          event.stopPropagation();
          this.showPopup();
          return false;
        }
      },
      { capture: true }
    );
  }

  /**
   * Shows the BIS popup and updates the variant information
   */
  showPopup() {
    // Show the popup
    this.classList.remove('hidden');
    this.classList.add('flex');

    // If there's a variant title, set it in the popup
    const variantTitle = document.querySelector('.js-title');
    const selectedVariantElem = document.querySelector('#selected-variant');
    if (variantTitle && selectedVariantElem) {
      selectedVariantElem.textContent = variantTitle.textContent.trim();
    }
  }

  /**
   * Sends the BIS request to Klaviyo
   */
  sendDataToKlaviyo(email, productId) {
    const url = 'https://a.klaviyo.com/client/back-in-stock-subscriptions/?company_id=U8rCt6';
    const productString = '$shopify:::$default:::' + productId;
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        revision: '2023-09-15',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        data: {
          type: 'back-in-stock-subscription',
          attributes: {
            channels: ['EMAIL'],
            profile: { data: { type: 'profile', attributes: { email: email } } }
          },
          relationships: {
            variant: { data: { type: 'catalog-variant', id: productString } }
          }
        }
      })
    };

    fetch(url, options)
      .then((res) => {
        if (res.ok) {
          this.querySelector('form').textContent = 'Thank You!';
        }
        return res.json();
      })
      .then((json) => console.log(json))
      .catch((err) => console.log(err));
  }

  /**
   * Subscribes the user to the mailing list in Klaviyo
   */
  sendListToKlaviyo(email) {
    const url = 'https://a.klaviyo.com/client/subscriptions/?company_id=U8rCt6';
    const options = {
      method: 'POST',
      headers: { revision: '2023-09-15', 'content-type': 'application/json' },
      body: JSON.stringify({
        data: {
          type: 'subscription',
          attributes: {
            custom_source: 'Back in stock form',
            profile: {
              data: {
                type: 'profile',
                attributes: {
                  email: email
                }
              }
            }
          },
          relationships: { list: { data: { type: 'list', id: 'XWPEqh' } } }
        }
      })
    };

    fetch(url, options)
      .then((res) => res.json())
      .then((json) => console.log(json))
      .catch((err) => console.error('error:' + err));
  }

  /**
   * Handles form submission
   */
  onSubmitHandler(event) {
    event.preventDefault();
    const email = this.querySelector('#email').value;
    const productId = document.querySelector('#productInformationForm .product-variant-id').value;
    const subscribeToList = this.querySelector('#subscribe-to-list').checked;
    console.log(email, productId, subscribeToList);
    this.sendDataToKlaviyo(email, productId);
    if (subscribeToList) this.sendListToKlaviyo(email);
  }

  /**
   * Closes the popup
   */
  onClickHandler(event) {
    this.classList.add('hidden');
    this.classList.remove('flex');
  }
}

customElements.define('klaviyo-bis', BISForm);
